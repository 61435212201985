import LegalLinks from './components/LegalLinks';

const Footer = () => {
  return (
    <footer className="bg-lilac-100">
      <div className="mx-auto max-w-7xl px-8 py-8 sm:px-16 lg:px-32">
        <LegalLinks />
      </div>
    </footer>
  );
};

export default Footer;
