import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'NotFoundPage';

export default defineMessages({
  notFoundTitle: {
    id: `${I18N_NAMESPACE}.notFoundTitle`,
    defaultMessage: 'Page not found',
  },

  notFoundParagraph: {
    id: `${I18N_NAMESPACE}.notFoundParagraph`,
    defaultMessage:
      'We rarely move content, therefore please double-check that the address is correct. Would you like to perform a calculation? Fill in your address at {link}.',
  },

  frontpageRef: {
    id: `${I18N_NAMESPACE}.frontpageRef`,
    defaultMessage: 'at the home page',
  },

  animationDescription: {
    id: `${I18N_NAMESPACE}.animationDescription`,
    defaultMessage: 'Sun-filled 404-animation',
  },

  headTitle: {
    id: `${I18N_NAMESPACE}.headTitle`,
    defaultMessage: '404, page not found!',
  },

  headDescription: {
    id: `${I18N_NAMESPACE}.headDescription`,
    defaultMessage: 'Aw, we could not find this page. Is your URL correct?',
  },

  contactUs: {
    id: `${I18N_NAMESPACE}.contactUs`,
    defaultMessage: 'Contact us at any time at {email}.',
  },
});
